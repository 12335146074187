import React from 'react';
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import TietoLogo from '../../assets/Logo/lifecare-logo.svg';
import UserProfileInfo from './user-info';

const useStyles = makeStyles(theme => ({
  svg: {
    width: '7.5rem',
  },
  navlinks: {
    marginLeft: theme.spacing(5),
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff',
    marginLeft: theme.spacing(5),
    '&:hover': {
      // borderBottom: '1px solid white',
    },
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '2.0',
    fontWeight: 400,
    textTransform: 'none',
    fontStyle: 'normal',
  },
  activeLink: {
    borderBottom: '2px solid #ffffff',
  },
}));

function Navbar() {
  const classes = useStyles();

  return (
    <AppBar position="static">
      <CssBaseline />
      <Toolbar>
        <Box display="flex" flexGrow={1}>
          <div>
            <img
              src={TietoLogo}
              className={classes.svg}
              alt="Lifecare Open Platform"
              title="Lifecare Open Platform"
            />
          </div>
          <div className={classes.navlinks}>
            <NavLink
              end
              to="/docs"
              className={({ isActive }) =>
                isActive
                  ? `${classes.link} ${classes.activeLink}`
                  : `${classes.link}`
              }
            >
              Home
            </NavLink>            
            <NavLink
              end
              to="/docs/default/component/care-desktop-admin-guide"
              className={({ isActive }) =>
                isActive
                  ? `${classes.link} ${classes.activeLink}`
                  : `${classes.link}`
              }
            >
               Admin User Guide
            </NavLink>
            <NavLink
              // hidden
              end
              to="/docs/default/component/care-desktop-architecture"
              className={({ isActive }) =>
                isActive
                  ? `${classes.link} ${classes.activeLink}`
                  : `${classes.link}`
              }
            >
              Architecture
            </NavLink>
            <NavLink
              //hidden
              end
              to="/docs/default/component/care-desktop-web-guide"
              className={({ isActive }) =>
                isActive
                  ? `${classes.link} ${classes.activeLink}`
                  : `${classes.link}`
              }
            >
               Care Professional Guide
            </NavLink>
            <NavLink
              // hidden
              end
              to="/docs/default/component/care-desktop-developer-guide"
              className={({ isActive }) =>
                isActive
                  ? `${classes.link} ${classes.activeLink}`
                  : `${classes.link}`
              }
            >
              Developer Guide
            </NavLink>
           
            <NavLink
              end
              to="/docs/default/component/qa-docs"
              className={({ isActive }) =>
                isActive
                  ? `${classes.link} ${classes.activeLink}`
                  : `${classes.link}`
              }
            >
              Quality assurance
            </NavLink>
            <NavLink
              end
              to="/docs/default/component/care-desktop-release-notes"
              className={({ isActive }) =>
                isActive
                  ? `${classes.link} ${classes.activeLink}`
                  : `${classes.link}`
              }
            >
              Releases
            </NavLink>
          </div>
        </Box>
        <UserProfileInfo />
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;
