import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis, oauth2AuthApiRef } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';

import {
  AlertDisplay,
  AutoLogout,
  IdentityProviders,
  OAuthRequestDialog,
  SignInPage,
  SignInProviderConfig,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { tietoTheme } from './theme/tietoTheme';
import { UnifiedThemeProvider } from '@backstage/theme';
import LightIcon from '@material-ui/icons/WbSunny';
import { CustomTechDocsHome } from './components/techdocs/CustomTechDocsHome';
import { CustomTecDocsReaderPage } from './components/techdocs/CustomTecDocsReaderPage';

const prodAuthProvider: SignInProviderConfig = {
  id: 'oauth2',
  title: 'Lifecare Open Platform',
  message: 'Please wait while we redirect you to our authentication portal. ',
  apiRef: oauth2AuthApiRef,
};

// const devAuthProviders: IdentityProviders = [
//   'guest',
//   {
//     id: 'microsoft',
//     title: 'Microsoft Authentication',
//     message: 'Sign in using Microsoft authentication',
//     apiRef: microsoftAuthApiRef,
//   },
// ];

// const prodAuthProvider: SignInProviderConfig = {
//   id: 'microsoft',
//   title: 'Microsoft Authentication',
//   message: 'Sign in using Microsoft authentication',
//   apiRef: microsoftAuthApiRef,
// };

const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: props => (
      <SignInPage
        auto
        align="center"
        title="Lifecare Open Platform"
        {...props}
        provider={prodAuthProvider}

        // providers={['guest']}
      />
    ),
  },
  themes: [
    {
      id: 'tieto-Theme',
      title: 'My Custom Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={tietoTheme} children={children} />
      ),
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="docs" />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>

    <Route path="/docs" element={<TechDocsIndexPage />}>
      <CustomTechDocsHome />
    </Route>

    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <CustomTecDocsReaderPage />
    </Route>

    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AutoLogout
      useWorkerTimers={false}
      idleTimeoutMinutes={30}
      promptBeforeIdleSeconds={30}
    />
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      {/* <Root>{routes}</Root> */}
      {routes}
    </AppRouter>
  </>,
);
