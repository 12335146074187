import React from 'react';

import { Content, ContentHeader } from '@backstage/core-components';
import {
  CatalogFilterLayout,
  EntityOwnerPicker,
  EntityTagPicker,
  UserListPicker,
  EntityListProvider,
} from '@backstage/plugin-catalog-react';
import {
  DocsCardGrid,
  EntityListDocsTable,
  TechDocsPageWrapper,
  TechDocsPicker,
} from '@backstage/plugin-techdocs';
import { Entity } from '@backstage/catalog-model';

import { EntityListDocsGrid } from '@backstage/plugin-techdocs';
import Navbar from '../Navbar/navbar';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  techDocsHome: {

    '& .MuiGrid-container': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      '& .MuiGrid-item:nth-child(1)':
      {
        border: "1px solid #00ff00",
        display: "none",
      },
      '& .MuiBox-root': {
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      }
    }
  }
}
));

export type CustomTechDocsHomeProps = {
  groups?: Array<{
    title: React.ReactNode;
    filterPredicate: ((entity: Entity) => boolean) | string;
  }>;
};

export const CustomTechDocsHome = ({ groups }: CustomTechDocsHomeProps) => {

  const classes = useStyles();
  return (
    <>
      <Navbar />
      <Content>
        <ContentHeader title="">
          <></>
        </ContentHeader>
        <div className={classes.techDocsHome}>
          <EntityListProvider >
            <CatalogFilterLayout>
              <CatalogFilterLayout.Filters >
                <TechDocsPicker />
              </CatalogFilterLayout.Filters>
              <CatalogFilterLayout.Content>
                <EntityListDocsGrid groups={groups} />
              </CatalogFilterLayout.Content>
            </CatalogFilterLayout>
          </EntityListProvider>
        </div>
      </Content>
    </>
  );
};
