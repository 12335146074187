import React, { useState } from 'react';
import {
  CardHeader,
  IconButton,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { useUserProfile } from '@backstage/plugin-user-settings';

import Avatar from '@material-ui/core/Avatar';

import {
  identityApiRef,
  errorApiRef,
  useApi,
} from '@backstage/core-plugin-api';

import SignOutIcon from '@material-ui/icons/Lock';
import InfoIcon from '@material-ui/icons/Info';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

const UserProfileInfo: React.FC = () => {
  const useStyles = makeStyles(theme => ({
    large: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  }));

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { profile, displayName } = useUserProfile();
  // const errorApi = useApi(errorApiRef);
  // const identityApi = useApi(identityApiRef);

  return (
    <>
      <IconButton
        onClick={handleMenuOpen}
        edge="end"
        color="inherit"
        aria-label="Account"
      >
        {/*  <Avatar src={profile.picture} className={classes.large}>
          <PersonOutlineIcon />
        </Avatar> */}
        <PersonOutlineIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="subtitle1">{displayName}</Typography>
          </div>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserProfileInfo;
