import {
    createBaseThemeOptions,
    createUnifiedTheme,
    defaultTypography,
    palettes,
} from '@backstage/theme';

import TietoevrySans1Regular from '../assets/fonts/TietoevrySans1-Regular.woff';
import TietoevrySans1Bold from '../assets/fonts/TietoevrySans1-Bold.woff';
import TietoevrySans1Thin from '../assets/fonts/TietoevrySans1-Thin.woff';


const tietoEvryCustomFontRegular = {
    fontFamily: '"Tietoevry_Sans_1"',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `url(${TietoevrySans1Regular}) format('woff')`,
};

const tietoEvryCustomFontBold = {
    fontFamily: '"Tietoevry_Sans_1"',
    fontStyle: 'normal',
    fontWeight: 700,
    src: `url(${TietoevrySans1Bold}) format('woff')`,
};

const tietoEvryCustomFontThin = {
    fontFamily: '"Tietoevry_Sans_1"',
    fontStyle: 'normal',
    fontWeight: 100,
    src: `url(${TietoevrySans1Thin}) format('woff')`,
};
export const tietoTheme = createUnifiedTheme({
    fontFamily: '"Tietoevry_Sans_1",Helvetica,Arial,sans-serif;',
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '@font-face': [tietoEvryCustomFontRegular, tietoEvryCustomFontBold, tietoEvryCustomFontThin],
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    gap: "0.5rem",
                    height: "3rem"
                },
                colorPrimary: {
                    color: "#EDEEEE",
                    backgroundColor: "#071d49",
                    left: "0",
                    position: "sticky",
                    right: "0",
                    top: "0",
                    zIndex: "4",
                    boxShadow: 'none',
                    borderBottom: '2px solid rgba(0, 0, 0, 0.2)',
                }
            },
        },
        MuiButton: {
            styleOverrides: {
                textPrimary: {
                    color: "#071D49bb !important",
                }
            },
        },
        BackstageHeader: {
            styleOverrides: {
                header: ({ theme }) => ({
                    backgroundColor: "#f8f7f7",
                    backgroundImage: "",
                    boxShadow: "",
                    display: "none",
                }),
                title: ({ theme }) => ({
                    color: "#280071cc"
                }),
            },
        },
        BackstageItemCardHeader: {
            styleOverrides: {
                root: {
                    color: "#EDEEEE",
                    background: '#071D49bb !important',
                    fontFamily: '"Tietoevry_Sans_1",Helvetica,Arial,sans-serif;',
                },
            },
        },
        BackstageContentHeader: {
            styleOverrides: {
                title: {
                    display: 'none',
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                regular: {
                    minHeight: "56px",
                    '@media (min-width: 600px)': {
                        minHeight: "56px"
                    },
                    '@media (min-width: 0px) and (orientation: landscape)': {
                        minHeight: "48px"
                    }
                },
            },
        },
       
    },
    ...createBaseThemeOptions({
        palette: palettes.light,

        typography: {
            ...defaultTypography,
            htmlFontSize: 16,
            fontFamily: '"Tietoevry_Sans_1",Helvetica,Arial,sans-serif;',
        },
        defaultPageTheme: 'home',
    }),
});