import { Page, Header, Content } from '@backstage/core-components';
import {
  TechDocsReaderPageContent,
  TechDocsReaderPageHeader,
  TechDocsReaderPageSubheader,
} from '@backstage/plugin-techdocs';
import React from 'react';
import Navbar from '../Navbar/navbar';

export const CustomTecDocsReaderPage = () => {
  return (
    <>
      <Navbar />
      <TechDocsReaderPageHeader />
      <TechDocsReaderPageContent withSearch={false} />
    </>
  );
};
